import { datadogRum, RumEvent } from "@datadog/browser-rum";

const IGNORED_ERRORS = [
  "%c[dreamdata-analytics]: Failed to load Clearbit script font-weight: bold;",
  "Clearbit tags.js snippet included twice."
];

export const initDatadog = () => {
  if (
    !process.env.REACT_APP_ALLOW_SESSION_MONITORING ||
    !process.env.REACT_APP_DATADOG_APP_ID ||
    !process.env.REACT_APP_DATADOG_CLIENT_TOKEN ||
    !process.env.REACT_APP_DATADOG_SITE ||
    !process.env.REACT_APP_DATADOG_SERVICE ||
    !process.env.REACT_APP_DATADOG_ENV
  ) {
    return;
  }

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_DATADOG_ENV,
    sessionSampleRate: 80,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    beforeSend: (event: RumEvent) => {
      if (event.type === "error" && IGNORED_ERRORS.includes(event.error.message)) {
        return false;
      }
      return true;
    }
  });
};
